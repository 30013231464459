import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const About = () => (
    <Layout>
        <SEO title="DataBings" />
        <h1>
            Sobre Nosotros
        </h1>
        <p>
            Aún no sabemos qué somos. Pero somos. 
        </p>
    </Layout>
)
export default About